import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header/header";
import SwiperCore, {
	Autoplay,
	Navigation,
	Pagination,
	EffectFlip,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/effect-flip/effect-flip.scss";
require("swiper/swiper-bundle.css");
import ReactPlayer from "react-player";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import parse from "html-react-parser";

SwiperCore.use([Autoplay, Navigation, Pagination, EffectFlip]);

const query = graphql`
	query {
		strapiPageVideos {
			title
			slug
			seo {
				metaDescription
				metaTitle
			}
		}
	}
`;

const Videos = () => {
	const data = useStaticQuery(query);
	const page = data.strapiPageVideos;
	const breakpoints = useBreakpoint();

	return (
		<Layout seo={page.seo}>
			<div className={page.slug}>
				<Header title={page.title}></Header>
				<div className={`container`}>
					<div className={`player-container`}>
						<Swiper
							slidesPerView={2}
							grabCursor={true}
							centeredSlides={true}
							speed={2000}
							className={`mt-5`}
							navigation
							pagination={{ clickable: true }}
							effect="flip"
						>
							<SwiperSlide>
								<div className="player-wrapper">
									<ReactPlayer
										url={`https://youtu.be/BPkINTBu8PM`}
										className="react-player"
										width="100%"
										height="100%"
										controls={true}
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="player-wrapper">
									<ReactPlayer
										url={`https://youtu.be/AMDTteNe_Ls`}
										className="react-player"
										width="100%"
										height="100%"
										controls={true}
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="player-wrapper">
									<ReactPlayer
										url={`https://youtu.be/xOvYN39wVGM`}
										className="react-player"
										width="100%"
										height="100%"
										controls={true}
									/>
								</div>
							</SwiperSlide>
						</Swiper>
					</div>{" "}
					<div className={`button-action`}>
						<div
							className={`grid grid-cols-1 lg:grid lg:grid-cols-2 gap-5 text-center`}
						>
							<div>
								<p className={`text-center`}>
									Afin de mieux comprendre certaines pratiques et réflexions sur
									la pédagogie PNL, Alain Thiry a regroupé plusieurs de ses
									vidéos au sein d'une chaîne Youtube accessible à tous.
									N'hésitez pas à vous y abonner !
								</p>
								<a
									href="https://www.youtube.com/channel/UCGi-2-2TgFMRR54UAlSw-nw/videos"
									target="_blank"
									rel="noopener"
								>
									<button className={`btn btn--green`}>
										Chaîne Youtube d'Alain Thiry
									</button>
								</a>
							</div>
							<div>
								<p className={`text-center`}>
									Deux professionnelles certifiées ayant suivi la formation
									d'Inter Actif se sont particulièrement intéressées aux
									mathématiques et ont créé une chaîne Youtube pour montrer
									l'usage de la pédagogie PNL dans les maths.
								</p>
								<a
									href="https://www.youtube.com/channel/UCaZXVZHfiM9xxCzQh3tjq9A/videos"
									target="_blank"
									rel="noopener"
								>
									<button
										className={`btn btn--green ${
											breakpoints.large ? `mt-5` : ``
										}`}
									>
										Chaîne "Atout Scolaire"
										{breakpoints.small ? parse(`<br></br>`) : ` `}
										Pédagogie PNL
									</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Videos;
